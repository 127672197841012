import { Easing, Tween } from '@tweenjs/tween.js'

export type Transform = {
  x: number
  y: number
  scaleX: number
  scaleY: number
  opacity: number
}

export class TranslateScale {
  private readonly transform: Transform = {
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    opacity: 1
  }
  private tween?: Tween<Transform>
  private startX = 0
  private startY = 0
  private startWidth = 0
  private startHeight = 0
  private startOpacity = 0

  constructor(private readonly node: HTMLElement) {}

  set(): void {
    const { x, y, width, height } = this.node.getBoundingClientRect()
    const opacity = parseFloat(window.getComputedStyle(this.node).getPropertyValue('opacity'))
    this.startX = x
    this.startY = y
    this.startWidth = width
    this.startHeight = height
    this.startOpacity = opacity
  }

  move(delay = 0, easing = Easing.Back.Out): void {
    this.tween?.stop()

    const { x, y, width, height } = this.node.getBoundingClientRect()
    const opacity = parseFloat(window.getComputedStyle(this.node).getPropertyValue('opacity'))
    this.transform.x = this.startX - x
    this.transform.y = this.startY - y
    this.transform.scaleX = this.startWidth / width
    this.transform.scaleY = this.startHeight / height
    this.transform.opacity = this.startOpacity

    this.setStyles()

    this.tween = new Tween(this.transform)
      .to({ x: 0, y: 0, scaleX: 1, scaleY: 1, opacity }, 550)
      .delay(delay)
      .easing(easing)
      .onUpdate(() => this.setStyles())
      .onComplete(() => this.removeStyles())
      .start()
  }

  setStyles(): void {
    const { x, y, scaleX, scaleY, opacity } = this.transform
    this.node.style.setProperty(
      'transform',
      `translate3d(${x}px,${y}px,0) scale(${scaleX}, ${scaleY})`
    )
    this.node.style.setProperty('opacity', `${opacity}`)
  }

  removeStyles(): void {
    this.node.style.removeProperty('transform')
    this.node.style.removeProperty('opacity')
  }
}
