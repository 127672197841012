.Main {
  display: none;
  position: absolute;
  justify-content: end;
  width: 100%;
  box-sizing: border-box;
  padding-inline: calc(var(--cell) * 2);
  padding-block: var(--cell);
  gap: 2em;
  font-size: 1.1vw;
  pointer-events: none;

  @media (min-width: 1024px) {
    display: flex;
  }
}

.Link {
  display: none;
  font-weight: 700;
  overflow: hidden;
  pointer-events: auto;
}

.Link svg {
  aspect-ratio: 18 /11;
  height: 0.75em;
  fill: currentColor;
}

.Link.IsVisible {
  display: block;
}

.Label {
  display: flex;
  padding-block: 0.5em;
  gap: 0.5em;
  align-items: center;
}
