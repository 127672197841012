.Main {
  position: relative;
  grid-area: main;
  display: grid;
  grid-template: minmax(0, 1fr) / minmax(0, 1fr);
  width: 100vw;
  opacity: 0;
}

.Main:after {
  display: none;
  z-index: 1000;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-size: var(--cell) var(--cell);
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0.25) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 0, 0, 0.25) 1px, transparent 1px);
}

.SliceRichText {
  display: grid;
  justify-content: center;
  align-content: center;
  padding-block: var(--cell);
  padding-inline: calc(var(--cell) * 0.5);
}

.SliceRichTextInner {
  max-width: 32ch;
}

.Notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 300;
  display: grid;
  justify-content: end;
  align-items: start;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 40%) minmax(0, 60%);
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms linear;

  @media (min-width: 1024px) {
    grid-template-columns: minmax(25rem, 30%) minmax(0, 30rem);
  }
}

.Notification.IsVisible {
  opacity: 1;
  pointer-events: auto;
}

.Container {
}

.Container svg {
  display: block;
}

.Text {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.4;
  padding-block: 2em;
  padding-inline-end: var(--cell);
  box-sizing: border-box;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
}

.Text p {
  padding-bottom: 1.4em;
}
