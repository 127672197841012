.Main {
  --color: var(--color-white);
  --color-button: var(--color-80);
  --color-background: var(--color-60);
  --color-shape: var(--color-100);

  position: relative;
  display: none;
  grid-column: 1/-1;
  color: var(--color);
  transition: opacity 250ms linear;

  @media (min-width: 1024px) {
    padding: 0;
    grid-column: 1;
    grid-row: 1;
    grid-template: minmax(0, 1fr) / minmax(0, 1fr);
  }
}

.IsVisible {
  display: grid;
}

.IsActive {
  display: grid;
}

.IsHidden {
  display: grid;
  @media (min-width: 1024px) {
    opacity: 0.125;
  }
}

.ShadePatternA {
  --color-button: var(--color-80);
}

.Yellow.ShadePatternA {
  --color-button: var(--color-60);
}

.Red.ShadePatternA {
  --color-button: var(--color-60);
}

.ShadePatternB {
  --color: var(--color-blue);
  --color-button: var(--color-40);
  --color-shape: var(--color-60);
}

.Purple.ShadePatternC {
  --color-button: var(--color-60);
  --color-shape: var(--color-100);
}

.Green.ShadePatternC {
  --color-button: var(--color-60);
  --color-shape: var(--color-100);
}

.Red.ShadePatternC {
  --color-button: var(--color-100);
  --color-shape: var(--color-60);
}

.Yellow.ShadePatternC {
  --color-button: var(--color-100);
  --color-shape: var(--color-60);
}

.Button {
  position: relative;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--cell) * 0.5);
  padding: calc(var(--cell) * 0.5);
  box-sizing: border-box;
  pointer-events: auto;
  will-change: opacity, transform;
  overflow: hidden;

  @media (min-width: 1024px) {
    font-size: 1vw;
    box-sizing: border-box;
    display: block;
    padding: calc(var(--cell) * 0.25);
    line-height: 1;
    grid-column: 1;
    grid-row: 1;
    align-self: start;
    height: calc(var(--cell) * 2);
    width: calc(var(--cell) * 2);
  }
}

.Purple.ShadePatternA .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 2);
    margin-inline-start: calc(var(--cell) * 9);
  }
}

.Purple.ShadePatternB .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 2);
    margin-inline-start: calc(var(--cell) * 11);
  }
}

.Purple.ShadePatternC .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 4);
    margin-inline-start: calc(var(--cell) * 11);
  }
}

.Green.ShadePatternA .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 1);
    margin-inline-start: calc(var(--cell) * 16);
  }
}

.Green.ShadePatternB .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 1);
    margin-inline-start: calc(var(--cell) * 18);
  }
}

.Green.ShadePatternC .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 18);
  }
}

.Red.ShadePatternA .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 1);
    margin-inline-start: calc(var(--cell) * 17);
  }
}

.Red.ShadePatternB .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 1);
    margin-inline-start: calc(var(--cell) * 19);
  }
}

.Red.ShadePatternC .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 19);
  }
}

.Yellow.ShadePatternA .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 4);
    margin-inline-start: calc(var(--cell) * 21);
  }
}

.Yellow.ShadePatternB .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 4);
    margin-inline-start: calc(var(--cell) * 23);
  }
}

.Yellow.ShadePatternC .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 6);
    margin-inline-start: calc(var(--cell) * 23);
  }
}

.IsActive.ShadePatternA .Button,
.IsHidden.ShadePatternA .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 1);
    margin-inline-start: calc(var(--cell) * 7);
  }
}

.IsActive.ShadePatternB .Button,
.IsHidden.ShadePatternB .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 1);
    margin-inline-start: calc(var(--cell) * 9);
  }
}

.IsActive.ShadePatternC .Button,
.IsHidden.ShadePatternC .Button {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 9);
  }
}

.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-button);
  border-radius: 0 0 0 calc(var(--cell) * 0.25);
  transform-origin: left top;
  will-change: opacity, transform;

  @media (min-width: 1024px) {
    border-radius: 0;
  }
}

.Wrapper {
  position: relative;
  display: block;
  overflow: hidden;

  @media (min-width: 1024px) {
    padding-block-end: 0.5em;
  }
}

.Label {
  display: block;
  -webkit-hyphens: auto;
  word-wrap: break-word;
  will-change: opacity, transform;
}

.Icon {
  position: relative;
  flex-shrink: 0;
  width: 0.85em;
  height: 0.85em;

  @media (min-width: 1024px) {
    display: none;
  }
}

.IsActive .Icon {
  transform: rotate(180deg);
}

.Icon svg {
  fill: currentColor;
}

.Text {
  padding-block: calc(var(--cell) * 0.5);
  box-sizing: border-box;
  display: none;
  color: var(--color-white);

  @media (min-width: 1024px) {
    position: sticky;
    top: 0;
    color: var(--color-blue);
    grid-column: 1;
    grid-row: 1;
    padding: calc(var(--cell) * 0.5);
    width: calc(var(--cell) * 7);
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 2);
  }
}

.Text p {
  padding-block-end: 1.5em;
}

.IsActive .Text {
  display: block;
}

.Subtitle {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    -webkit-hyphens: auto;
    hyphens: auto;
    overflow-wrap: anywhere;
  }
}

.Articles {
  position: relative;
  display: none;
  gap: 0.625rem;
  padding-block-start: 0.625rem;
  overflow: hidden;

  @media (min-width: 1024px) {
    padding: 0;
    grid-column: 1;
    grid-row: 1;
  }
}

.IsActive .Articles {
  display: grid;
}

.Scroll {
  position: fixed;
  justify-content: center;
  align-content: end;
  box-sizing: border-box;
  padding-block-end: calc(var(--cell) * 0.5);
  top: calc(100vh - var(--cell) * 4);
  left: 0;
  width: 100%;
  height: calc(var(--cell) * 4);
  color: var(--color-blue);
  z-index: 500;
  pointer-events: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  opacity: 0;
  transition: opacity 250ms linear;
  display: none;
  will-change: opacity;

  @media (min-width: 1024px) {
    display: grid;
  }
}

.Scroll.IsVisible {
  opacity: 1;
}

.ScrollLabel {
  position: relative;
  width: 2vw;
  height: 2vw;
}

.ScrollLabel svg {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  height: 90%;
  fill: currentColor;
}

.Log {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: normal;
  z-index: 300;
  color: black;
}
