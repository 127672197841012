import { Easing } from '@tweenjs/tween.js'

import { App } from '../app/App'
import { Animator } from './Animator'
import articleStyles from './Article.module.css'
import { ArticleController } from './ArticleController'
import styles from './ArticleGroup.module.css'
import { TranslateScale } from './TranslateScale'

type State = 'default' | 'visible' | 'active' | 'hidden'

export class ArticleGroupController {
  private readonly id: string
  private readonly articles: ArticleController[]
  private readonly button: TranslateScale
  private readonly background: Animator
  private readonly label: Animator
  private readonly text: Animator
  private readonly scroll: HTMLElement

  private state: State = 'default'
  private prevState: State = 'default'

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
    private readonly sectionId: string,
    private readonly index: number
  ) {
    this.onScroll = this.onScroll.bind(this)
    this.app = app
    this.node = node
    this.id = node.dataset.id || ''

    this.scroll = this.node.querySelector(`.${styles.Scroll}`) as HTMLElement

    this.button = new TranslateScale(this.node.querySelector(`.${styles.Button}`) as HTMLElement)

    this.background = new Animator(
      this.node.querySelector(`.${styles.Background}`) as HTMLElement,
      '%'
    )
    this.label = new Animator(this.node.querySelector(`.${styles.Label}`) as HTMLElement, '%')
    this.text = new Animator(this.node.querySelector(`.${styles.Text}`) as HTMLElement, '%')

    this.articles = Array.from(
      node.querySelectorAll(`.${articleStyles.Main}`) as NodeListOf<HTMLElement>
    ).map((node, index) => new ArticleController(node, app, sectionId, this.id, index))
  }

  toggle(sectionId?: string, groupId?: string, articleId?: string, initial = false): void {
    this.articles.forEach((controller) => controller.toggle(sectionId, groupId, articleId, initial))

    this.prevState = this.state

    if (this.sectionId === sectionId && !groupId) {
      this.state = 'visible'
    } else if (this.id === groupId) {
      this.state = 'active'
    } else if (this.sectionId === sectionId && groupId) {
      this.state = 'hidden'
    } else {
      this.state = 'default'
    }

    if (this.state === this.prevState) {
      return
    }

    this.button.set()

    this.setClassList(this.state)

    if (this.state === 'active' && this.articles.length > 4) {
      this.scroll.classList.add(styles.IsVisible)
      window.addEventListener('scroll', this.onScroll)
    }

    if (!initial) {
      this.animate()
    }
  }

  async animate(): Promise<void> {
    if (!this.app.mq.matches) return

    this.button.move()

    if (this.state === 'visible' && this.prevState !== 'active' && this.prevState !== 'hidden') {
      this.background.show(
        { scaleX: 0 },
        { scaleX: 1 },
        { delay: 350 + this.index * 150, easing: Easing.Back.Out }
      )
      this.label.show({ y: -125 }, { y: 0 }, { delay: 500 + this.index * 150 })
    }

    if (this.state === 'active') {
      this.text.show({ y: -3, opacity: 0 }, { y: 0, opacity: 1 }, { delay: 250 })
    }
  }

  setClassList(state: State): void {
    this.scroll.classList.remove(styles.IsVisible)

    this.node.classList.remove(styles.IsVisible)
    this.node.classList.remove(styles.IsActive)
    this.node.classList.remove(styles.IsHidden)

    switch (state) {
      case 'active':
        this.node.classList.add(styles.IsActive)
        break
      case 'visible':
        this.node.classList.add(styles.IsVisible)
        break
      case 'hidden':
        this.node.classList.add(styles.IsHidden)
        break
    }
  }

  onScroll(): void {
    this.scroll.classList.remove(styles.IsVisible)
    window.removeEventListener('scroll', this.onScroll)
  }
}
