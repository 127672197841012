import { EventEmitter } from './EventEmitter'

export class Router extends EventEmitter {
  public readonly routes: string[] = []
  private readonly mq: MediaQueryList
  public activeRoute: string

  constructor() {
    super()
    this.routes = window.__ROUTES__
    this.activeRoute = window.location.pathname
    this.mq = window.matchMedia('(min-width:1024px)')
    window.history.scrollRestoration = 'manual'
    window.addEventListener('popstate', this.onPopState.bind(this))
  }

  push(route: string): void {
    const { pathname, search, hash } = new URL(`${window.location.origin}${route}`)

    const newRoute = `${pathname}${search}${hash}`

    if (!this.mq.matches && newRoute === this.activeRoute) {
      this.goBack()
      return
    }

    this.activeRoute = newRoute
    window.history.pushState({}, this.activeRoute, `${window.location.origin}${this.activeRoute}`)
    this.emit('change', this.activeRoute as never)
  }

  onPopState(): void {
    const url = window.location.pathname

    if (url === this.activeRoute) {
      return
    }

    this.activeRoute = url
    this.emit('change', this.activeRoute as never)
  }

  goBack(): void {
    const routeArray = this.activeRoute.split('/').slice(1).slice(0, -2)

    if (routeArray.length > 0) {
      this.activeRoute = `/${routeArray.join('/')}/`

      window.history.pushState({}, this.activeRoute, `${window.location.origin}${this.activeRoute}`)
      this.emit('change', this.activeRoute as never)
    }
  }
}
