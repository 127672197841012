import { Animator } from './Animator'
import styles from './SectionNavigation.module.css'

class LinkController {
  private readonly id: string
  private readonly label: Animator
  private state: 'visible' | 'hidden' = 'hidden'
  private prevState: 'visible' | 'hidden' = 'hidden'

  constructor(
    private readonly node: HTMLElement,
    private readonly index: number
  ) {
    this.id = node.dataset.id || ''
    this.label = new Animator(this.node.querySelector(`.${styles.Label}`) as HTMLElement, '%')
  }

  update(sectionId?: string, groupId?: string) {
    if (groupId && sectionId !== this.id) {
      this.node.classList.add(styles.IsVisible)
      this.state = 'visible'
    } else {
      this.node.classList.remove(styles.IsVisible)
      this.state = 'hidden'
    }

    if (this.prevState === 'hidden' && this.state === 'visible') {
      this.label.show({ y: -125 }, { y: 0 }, { delay: this.index * 50 })
    }

    this.prevState = this.state
  }
}

export class SectionNavigationController {
  private readonly linkControllers: LinkController[]

  constructor(node: HTMLElement) {
    this.linkControllers = Array.from(
      node.querySelectorAll(`.${styles.Link}`) as NodeListOf<HTMLElement>
    ).map((node, index) => new LinkController(node, index))
  }

  update(sectionId?: string, groupId?: string): void {
    this.linkControllers.forEach((link) => link.update(sectionId, groupId))
  }
}
