import { Easing, Tween } from '@tweenjs/tween.js'

type Transform = {
  x?: number
  y?: number
  scaleX?: number
  scaleY?: number
  opacity?: number
}

type Options = {
  duration?: number
  delay?: number
  easing?: (amount: number) => number
}

type Unit = 'px' | '%' | 'em'

export class Animator {
  private readonly transform: Transform = {
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    opacity: 1
  }
  private tween?: Tween<Transform>

  constructor(
    private readonly node: HTMLElement,
    private readonly unit: Unit
  ) {}

  show(from: Transform, to: Transform, options?: Options): void {
    this.tween?.stop()

    this.transform.x = from.x ?? this.transform.x
    this.transform.y = from.y ?? this.transform.y
    this.transform.scaleX = from.scaleX ?? this.transform.scaleX
    this.transform.scaleY = from.scaleY ?? this.transform.scaleY
    this.transform.opacity = from.opacity ?? this.transform.opacity

    this.setStyles()

    this.tween = new Tween(this.transform)
      .to(to, options?.duration ?? 500)
      .delay(options?.delay ?? 0)
      .easing(options?.easing ?? Easing.Exponential.Out)
      .onUpdate(() => this.setStyles())
      .onComplete(() => this.removeStyles())
      .start()
  }

  setStyles(): void {
    const { x, y, scaleX, scaleY, opacity } = this.transform
    this.node.style.setProperty(
      'transform',
      `translate3d(${x}${this.unit},${y}${this.unit},0) scale(${scaleX},${scaleY})`
    )

    this.node.style.setProperty('opacity', `${opacity}`)
  }

  removeStyles(): void {
    this.node.style.removeProperty('transform')
    this.node.style.removeProperty('opacity')
  }
}
