import { Easing } from '@tweenjs/tween.js'
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light'

import { SectionDocument } from '../@types/types.generated'
import { App } from '../app/App'
import { delay } from '../utils/delay'
import { Animator } from './Animator'
import articleGroupStyles from './ArticleGroup.module.css'
import { ArticleGroupController } from './ArticleGroupController'
import styles from './Section.module.css'
import { TranslateScale } from './TranslateScale'

type Style = SectionDocument['data']['style']

const INTRO_DELAY: { [key in Style]: number } = {
  Purple: 150,
  Green: 100,
  Red: 50,
  Yellow: 0
}

type State = 'default' | 'active' | 'subActive' | 'hidden' | 'passive' | 'intro'

export class SectionController {
  private readonly articleGroupControllers: ArticleGroupController[]
  private readonly style: Style
  private readonly background: TranslateScale
  private readonly illustration: TranslateScale
  private readonly label: Animator
  private readonly inner: TranslateScale
  private readonly text: Animator
  private readonly container: HTMLElement
  private readonly svg: Animator
  private readonly wrapper: Animator
  private readonly title: Animator

  private state: State = 'default'
  private prevState: State = 'default'
  private animation?: AnimationItem

  public readonly id: string

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App
  ) {
    this.id = node.dataset.id || ''
    this.style = node.dataset.style as Style
    this.app = app
    this.node = node

    this.container = this.node.querySelector(`.${styles.Container}`) as HTMLElement

    this.background = new TranslateScale(
      this.node.querySelector(`.${styles.Background}`) as HTMLElement
    )
    this.inner = new TranslateScale(this.node.querySelector(`.${styles.Inner}`) as HTMLElement)
    this.illustration = new TranslateScale(this.container)

    this.wrapper = new Animator(this.node.querySelector(`.${styles.Wrapper}`) as HTMLElement, '%')
    this.svg = new Animator(this.node.querySelector(`.${styles.SVG}`) as HTMLElement, '%')
    this.label = new Animator(this.node.querySelector(`.${styles.Label}`) as HTMLElement, '%')
    this.text = new Animator(this.node.querySelector(`.${styles.RichText}`) as HTMLElement, 'em')
    this.title = new Animator(this.node.querySelector(`.${styles.TitleInner}`) as HTMLElement, '%')

    this.articleGroupControllers = Array.from(
      node.querySelectorAll(`.${articleGroupStyles.Main}`) as NodeListOf<HTMLElement>
    ).map((node, index) => new ArticleGroupController(node, app, this.id, index))
  }

  async load(): Promise<void> {
    const animation = this.node.dataset.animation || ''
    const extension = animation.split('.').pop()
    if (extension !== 'json') {
      return
    }

    try {
      const response = await fetch(animation)
      const data = await response.json()

      this.animation = lottie.loadAnimation({
        container: this.container,
        loop: true,
        animationData: data
      })
      return new Promise((resolve) => this.animation?.addEventListener('DOMLoaded', resolve))
    } catch (error) {
      console.error(animation, error)
    }
  }

  async toggle(
    sectionId?: string,
    groupId?: string,
    articleId?: string,
    initial = false
  ): Promise<void> {
    this.articleGroupControllers.forEach((controller) =>
      controller.toggle(sectionId, groupId, articleId, initial)
    )

    this.prevState = this.state

    if (this.id === sectionId && groupId) {
      this.state = 'subActive'
    } else if (this.id === sectionId) {
      this.state = 'active'
    } else if (sectionId && groupId) {
      this.state = 'passive'
    } else if (sectionId) {
      this.state = 'hidden'
    } else if (initial) {
      this.state = 'intro'
    } else {
      this.state = 'default'
    }

    if (this.state === this.prevState) {
      return
    }

    this.illustration.set()
    this.background.set()
    this.inner.set()

    this.setClassList(this.state)

    if (this.state === 'passive' || this.state === 'hidden' || this.state === 'intro') {
      this.animation?.goToAndStop(0)
    }

    if (!initial) {
      await this.animate()
    } else if (!sectionId) {
      await this.animateIntro()
    }

    if (this.state === 'active' || this.state === 'default') {
      this.animation?.play()
    }
  }

  async animate(): Promise<void> {
    if (this.prevState === 'intro') {
      const delay = INTRO_DELAY[this.style]
      this.background.move(delay)
      this.inner.move(delay)
      this.label.show({ y: -125 }, { y: 0 }, { delay: delay + 250 })
      this.wrapper.show(
        { opacity: 0, scaleX: 0.8, scaleY: 0.8 },
        { opacity: 1, scaleX: 1, scaleY: 1 },
        { duration: 350, delay: delay + 250, easing: Easing.Back.Out }
      )
      this.svg.show(
        { opacity: 0, scaleX: 0.8, scaleY: 0.8 },
        { opacity: 1, scaleX: 1, scaleY: 1 },
        { delay: delay + 250, easing: Easing.Back.Out }
      )
    }

    if (!this.app.mq.matches) return

    if (this.prevState !== 'intro') {
      this.background.move()
      this.inner.move()
    }

    if (this.prevState !== 'passive') {
      this.illustration.move()
    }

    if (this.state === 'active') {
      this.text.show({ y: -3, opacity: 0 }, { y: 0, opacity: 1 }, { delay: 150 })
    }

    if (this.state === 'subActive') {
      this.title.show({ y: -125 }, { y: 0 }, { delay: 250 })
    }

    if (this.state === 'active' || (this.state === 'default' && this.prevState === 'active')) {
      this.label.show({ y: -125 }, { y: 0 }, { delay: 250 })
    }

    await delay(600)
  }

  async animateIntro(): Promise<void> {
    this.background.set()
    this.inner.set()
    await delay(250)
    this.prevState = this.state
    this.state = 'default'
    this.setClassList(this.state)
    this.animate()
  }

  setClassList(state: State): void {
    this.node.classList.remove(styles.IsSubActive)
    this.node.classList.remove(styles.IsActive)
    this.node.classList.remove(styles.IsPassive)
    this.node.classList.remove(styles.IsHidden)
    this.node.classList.remove(styles.IsIntro)

    switch (state) {
      case 'subActive':
        this.node.classList.add(styles.IsSubActive)
        break
      case 'active':
        this.node.classList.add(styles.IsActive)
        break
      case 'passive':
        this.node.classList.add(styles.IsPassive)
        break
      case 'hidden':
        this.node.classList.add(styles.IsHidden)
        break
      case 'intro':
        this.node.classList.add(styles.IsIntro)
        break
    }
  }
}
