import Cookies from 'js-cookie'
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light'

import styles from './Layout.module.css'

const COOKIE = 'jobs.baloise.com-notification'

export class NotificationController {
  private readonly container: HTMLElement
  private clicks = 0
  private loaded = false
  private animation?: AnimationItem
  private shown = false

  constructor(private readonly node: HTMLElement) {
    this.node = node
    this.shown = Cookies.get(COOKIE) === 'true'
    this.container = this.node.querySelector(`.${styles.Container}`) as HTMLElement

    if (this.shown) {
      this.node.addEventListener('click', this.close.bind(this))
    }
  }

  async load(): Promise<void> {
    if (this.loaded) {
      return
    }
    const animation = this.container.dataset.animation || ''
    const extension = animation.split('.').pop()
    if (extension !== 'json') {
      return
    }

    try {
      const response = await fetch(animation)
      const data = await response.json()

      this.animation = lottie.loadAnimation({
        container: this.container,
        loop: true,
        animationData: data
      })
      return new Promise(
        (resolve) =>
          this.animation?.addEventListener('DOMLoaded', () => {
            this.loaded = true
            resolve()
          })
      )
    } catch (error) {
      console.error(animation, error)
    }
  }

  resize(headerHeight: number): void {
    this.node.style.paddingBlockStart = `${headerHeight}px`
  }

  async update(): Promise<void> {
    this.clicks++
    if (!this.shown && this.clicks === 4) {
      Cookies.set(COOKIE, 'true', { expires: 30 })
      this.shown = true
      await this.load()
      this.node.classList.add(styles.IsVisible)
      this.animation?.play()
    } else {
      this.node.classList.remove(styles.IsVisible)
      this.animation?.pause()
    }
  }

  close(event: MouseEvent): void {
    event.stopPropagation()
    this.node.classList.remove(styles.IsVisible)
    this.animation?.pause()
  }
}
