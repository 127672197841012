export type CustomType = 'footer' | 'header' | 'section' | 'page'

export enum Lang {
  de = 'de-ch',
  en = 'en-eu',
  fr = 'fr-ch',
  it = 'it-ch'
}

export enum ShortLang {
  'de-ch' = 'de',
  'en-eu' = 'en',
  'fr-ch' = 'fr',
  'it-ch' = 'it'
}

export interface Controller {
  dispose?(): void

  resize?(): void

  show?(): void

  hide?(): void

  update?(time: number): void

  load?(): Promise<void>

  scroll?(scrollY: number): void

  open?(): void

  close?(): void
}

export type ShadePattern = 'A' | 'B' | 'C'

export const NUM_COLUMNS_DESKTOP = 29
export const NUM_COLUMNS_MOBILE = 13
