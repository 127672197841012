type Listener = (...args: never[]) => void
type Events = {
  [event: string]: Listener[]
}

export class EventEmitter {
  private readonly events: Events = {}

  public on(event: string, listener: Listener): () => void {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = []
    }

    this.events[event].push(listener)
    return () => this.removeListener(event, listener)
  }

  public removeListener(event: string, listener: Listener): void {
    if (typeof this.events[event] !== 'object') {
      return
    }

    const index: number = this.events[event].indexOf(listener)
    if (index > -1) {
      this.events[event].splice(index, 1)
    }
  }

  public removeAllListeners(): void {
    Object.keys(this.events).forEach((event: string) =>
      this.events[event].splice(0, this.events[event].length)
    )
  }

  public emit(event: string, ...args: never[]): void {
    if (typeof this.events[event] !== 'object') {
      return
    }

    ;[...this.events[event]].forEach((listener) => listener.apply(this, args))
  }

  public once(event: string, listener: Listener): () => void {
    const remove: () => void = this.on(event, (...args: never[]) => {
      remove()
      listener.apply(this, args)
    })

    return remove
  }
}
