.Main {
  grid-area: header;
  position: sticky;
  top: 0;
  grid-template-rows: min-content minmax(0, 1fr);
  z-index: 400;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  padding-inline: calc(var(--cell) * 0.5);
  background-color: var(--color-white);
  border-block-end: 1px solid var(--color-grey);
  font-size: 0.875rem;
  padding-block: 0.5em;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
}

.Title {
  display: block;
  font-weight: 900;
  line-height: 1.2;
}

.Cta a {
  font-size: 0.75rem;
  display: block;
  padding: 0.5em 1.5em;
  border-radius: 5px;
  border-width: 0;
  line-height: 1.2;
  font-weight: 700;
  background-color: var(--color-blue);
  color: var(--color-white);
  text-decoration: none;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
}
