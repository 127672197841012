import { Easing } from '@tweenjs/tween.js'
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light'

import { App } from '../app/App'
import { Animator } from './Animator'
import styles from './Article.module.css'
import { TranslateScale } from './TranslateScale'

type State = 'default' | 'visible' | 'active' | 'hidden'

export class ArticleController {
  private readonly id: string
  private readonly background: TranslateScale
  private readonly text: Animator
  private readonly label: Animator
  private readonly shape: Animator
  private readonly inner: Animator
  private readonly svg: Animator
  private readonly illustration: Animator
  private readonly body: HTMLElement
  private readonly container: HTMLElement

  private animation?: AnimationItem
  private state: State = 'default'
  private prevState: State = 'default'

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
    private readonly sectionId: string,
    private readonly groupId: string,
    private readonly index: number
  ) {
    this.id = node.dataset.id as string

    this.container = this.node.querySelector(`.${styles.Container}`) as HTMLElement
    this.body = this.node.querySelector(`.${styles.Body}`) as HTMLElement

    this.background = new TranslateScale(
      this.node.querySelector(`.${styles.Background}`) as HTMLElement
    )
    this.illustration = new Animator(
      this.node.querySelector(`.${styles.Illustration}`) as HTMLElement,
      '%'
    )
    this.inner = new Animator(this.node.querySelector(`.${styles.Inner}`) as HTMLElement, '%')
    this.svg = new Animator(this.node.querySelector(`.${styles.SVG}`) as HTMLElement, '%')
    this.shape = new Animator(this.node.querySelector(`.${styles.Shape}`) as HTMLElement, '%')
    this.label = new Animator(this.node.querySelector(`.${styles.Label}`) as HTMLElement, '%')
    this.text = new Animator(this.node.querySelector(`.${styles.RichText}`) as HTMLElement, 'em')

    this.load()
  }

  async load(): Promise<void> {
    const animation = this.node.dataset.animation || ''
    const extension = animation.split('.').pop()
    if (extension !== 'json') {
      return
    }

    try {
      const response = await fetch(animation)
      const data = await response.json()

      this.animation = lottie.loadAnimation({
        container: this.container,
        loop: true,
        animationData: data
      })
    } catch (error) {
      console.error(animation, error)
    }
  }

  toggle(sectionId?: string, groupId?: string, articleId?: string, initial = false): void {
    this.prevState = this.state

    if (this.sectionId === sectionId && this.groupId === groupId && !articleId) {
      this.state = 'visible'
    } else if (this.id === articleId) {
      this.state = 'active'
    } else if (this.sectionId === sectionId && this.groupId === groupId && articleId) {
      this.state = 'hidden'
    } else {
      this.state = 'default'
    }

    if (this.state === this.prevState) {
      return
    }

    this.background.set()

    if (this.state === 'active') {
      this.animation?.play()
    } else {
      this.animation?.goToAndStop(0)
    }

    this.setClassList(this.state)

    if (!initial) {
      this.animate()
    }
  }

  async animate(): Promise<void> {
    if (!this.app.mq.matches) return

    if (this.state === 'active') {
      this.text.show({ y: -3, opacity: 0 }, { y: 0, opacity: 1 }, { delay: 150 })
      this.label.show({ y: -125 }, { y: 0 }, { delay: 150 })
      this.illustration.show(
        { opacity: 0, scaleX: 0.8, scaleY: 0.8 },
        { opacity: 1, scaleX: 1, scaleY: 1 },
        { delay: 150, easing: Easing.Back.Out }
      )

      this.body.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (this.state === 'visible' && this.prevState !== 'hidden') {
      this.label.show({ y: -125 }, { y: 0 }, { delay: 150 })
    }

    if (this.state === 'visible' && this.prevState === 'default') {
      this.inner.show({ x: 110 }, { x: 0 }, { delay: this.index * 150 })
      this.label.show({ y: -125 }, { y: 0 }, { delay: 150 + this.index * 150 })
      this.svg.show(
        { opacity: 0, scaleX: 0.8, scaleY: 0.8 },
        { opacity: 1, scaleX: 1, scaleY: 1 },
        { delay: 150 + this.index * 150, easing: Easing.Back.Out }
      )
      this.shape.show(
        { scaleX: 0 },
        { scaleX: 1 },
        { delay: 250 + this.index * 150, easing: Easing.Back.Out }
      )
    }

    if (this.state === 'active' || (this.state === 'visible' && this.prevState === 'active')) {
      this.background.move()
    }
  }

  setClassList(state: State): void {
    this.node.classList.remove(styles.IsVisible)
    this.node.classList.remove(styles.IsActive)
    this.node.classList.remove(styles.IsHidden)

    switch (state) {
      case 'active':
        this.node.classList.add(styles.IsActive)
        break
      case 'visible':
        this.node.classList.add(styles.IsVisible)
        break
      case 'hidden':
        this.node.classList.add(styles.IsHidden)
        break
    }
  }
}
