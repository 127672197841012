import '../fonts/index.css'
import '../Body.module.css'
import '../components/Footer.module.css'
import '../utils/Typography.module.css'

import TWEEN from '@tweenjs/tween.js'

import headerStyles from '../components/Header.module.css'
import layoutStyles from '../components/Layout.module.css'
import { NotificationController } from '../components/NotificationController'
import mainSectionStyles from '../components/Section.module.css'
import { SectionController } from '../components/SectionController'
import sectionNavigationStyles from '../components/SectionNavigation.module.css'
import { SectionNavigationController } from '../components/SectionNavigationController'
import { NUM_COLUMNS_DESKTOP, NUM_COLUMNS_MOBILE } from '../const'
import { EventEmitter } from './EventEmitter'
import { Router } from './Router'

export class App extends EventEmitter {
  private readonly observer: ResizeObserver

  private readonly header: HTMLElement
  private readonly main: HTMLElement
  private readonly sectionControllers: SectionController[]
  private readonly notificationController?: NotificationController
  private readonly sectionNavigationController?: SectionNavigationController

  public readonly router: Router
  public readonly mq = window.matchMedia('(min-width: 1024px)')

  private cell = 0
  public windowWidth = 0
  public windowHeight = 0

  constructor() {
    super()

    this.update = this.update.bind(this)

    this.main = document.querySelector(`.${layoutStyles.Main}`) as HTMLElement
    this.header = document.querySelector(`.${headerStyles.Main}`) as HTMLElement

    const notification = this.main.querySelector(`.${layoutStyles.Notification}`) as HTMLElement
    if (notification) {
      this.notificationController = new NotificationController(notification)
    }
    const sectionNavigation = this.main.querySelector(
      `.${sectionNavigationStyles.Main}`
    ) as HTMLElement
    if (sectionNavigation) {
      this.sectionNavigationController = new SectionNavigationController(sectionNavigation)
    }

    this.sectionControllers = this.getNodes(mainSectionStyles.Main).map(
      (node) => new SectionController(node, this)
    )

    this.router = new Router()
    this.router.on('change', this.onNavigate.bind(this))
    this.main.addEventListener('click', this.onDocumentClick.bind(this))

    this.observer = new ResizeObserver(this.onResize.bind(this))
    this.observer.observe(document.body)

    Promise.all([
      document.fonts.ready,
      ...this.sectionControllers.map((controller) => controller.load())
    ]).then(() => this.init())
  }

  async init(): Promise<void> {
    this.onResize()
    requestAnimationFrame(this.update)

    const [sectionId, groupId, articleId] = this.router.activeRoute.split('/').slice(2)
    await Promise.all(
      this.sectionControllers.map((controller) =>
        controller.toggle(sectionId, groupId, articleId, true)
      )
    )
    this.sectionNavigationController?.update(sectionId, groupId)

    this.main.style.setProperty('opacity', '1')
  }

  onDocumentClick(event: MouseEvent): void {
    let target: HTMLElement = event.target as HTMLElement
    let anchorClick = false

    while (target && target.parentNode) {
      if (target.tagName === 'A') {
        const { origin, pathname, hash } = new URL((target as HTMLAnchorElement).href)
        if (origin === window.location.origin && !(hash && pathname === window.location.pathname)) {
          const href = (target as HTMLAnchorElement).getAttribute('href')
          if (href && this.router.routes.includes(href)) {
            event.preventDefault()
            this.router.push(href)
          }
        }
        anchorClick = true
        break
      }
      target = target.parentNode as HTMLElement
    }

    if (!anchorClick) {
      this.router.goBack()
    }
  }

  async onNavigate(route: string): Promise<void> {
    const [sectionId, groupId, articleId] = route.split('/').slice(2)

    this.sectionControllers.forEach((controller) => {
      controller.toggle(sectionId, groupId, articleId)
    })

    this.sectionNavigationController?.update(sectionId, groupId)
    this.notificationController?.update()
  }

  getNodes(className: string): HTMLElement[] {
    return Array.from(this.main?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
  }

  onResize(): void {
    const mainWidth = this.main.getBoundingClientRect().width
    const headerHeight = this.header.getBoundingClientRect().height

    const numColumns = this.mq.matches ? NUM_COLUMNS_DESKTOP : NUM_COLUMNS_MOBILE
    const cell = Math.floor(mainWidth / numColumns)
    this.notificationController?.resize(headerHeight)
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight

    if (cell > 0 && this.cell !== cell) {
      this.cell = cell
      document.documentElement.style.setProperty('--cell', `${cell}px`)
      document.documentElement.style.setProperty('--header', `${headerHeight}px`)
    }
  }

  update(time: number): void {
    requestAnimationFrame(this.update)
    TWEEN.update(time)
  }
}
