.Main {
  position: relative;
  display: none;
  grid-column: 1 / -1;
  color: var(--color);

  @media (min-width: 1024px) {
    grid-column: 1;
    grid-row: 1;
    background: transparent;
    transition: opacity linear 250ms;
  }
}

.IsVisible,
.IsActive,
.IsHidden {
  display: block;
}

.IsHidden {
  @media (min-width: 1024px) {
    opacity: 0.125;
  }
}

.Pattern {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: calc(var(--cell) * 7);
    height: calc(var(--cell) * 7);
    transition: opacity linear 250ms;
    overflow: hidden;
  }
}

.Main:nth-child(1) .Pattern,
.Main:nth-child(5) .Pattern,
.Main:nth-child(9) .Pattern {
  left: calc(var(--cell) * 14);
}

.Main:nth-child(2) .Pattern,
.Main:nth-child(6) .Pattern {
  left: calc(var(--cell) * 21);
}

.Main:nth-child(3) .Pattern,
.Main:nth-child(7) .Pattern {
  left: calc(var(--cell) * 12);
}

.Main:nth-child(4) .Pattern,
.Main:nth-child(8) .Pattern {
  left: calc(var(--cell) * 19);
}

.Main:nth-child(1) .Pattern {
  top: calc(var(--cell) * 4);
}

.Main:nth-child(2) .Pattern {
  top: calc(var(--cell) * 5);
}

.Main:nth-child(3) .Pattern {
  top: calc(var(--cell) * 7);
}

.Main:nth-child(4) .Pattern {
  top: calc(var(--cell) * 8);
}

.Main:nth-child(5) .Pattern {
  top: calc(var(--cell) * 14);
}

.Main:nth-child(6) .Pattern {
  top: calc(var(--cell) * 15);
}

.Main:nth-child(7) .Pattern {
  top: calc(var(--cell) * 17);
}

.Main:nth-child(8) .Pattern {
  top: calc(var(--cell) * 19);
}

.Main:nth-child(9) .Pattern {
  top: calc(var(--cell) * 24);
}

.SVG {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: var(--color-20);
  will-change: opacity, transform;
}

.Shape {
  display: none;

  @media (min-width: 1024px) {
    position: absolute;
    display: block;
    background-color: var(--color-shape);
    transform-origin: left top;
    transition: opacity 250ms linear;
    will-change: opacity, transform;
  }
}

.Main:nth-child(1) .Shape {
  border-radius: 0 0 calc(var(--cell) * 0.125) 0;
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 17);
  top: calc(var(--cell) * 6);
}

.Main:nth-child(2) .Shape {
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 23);
  top: calc(var(--cell) * 4);
}

.Main:nth-child(3) .Shape {
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 15);
  top: calc(var(--cell) * 11);
}

.Main:nth-child(4) .Shape {
  border-radius: 0 0 0 calc(var(--cell) * 0.125);
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 19);
  top: calc(var(--cell) * 10);
}

.Main:nth-child(5) .Shape {
  border-radius: 0 0 calc(var(--cell) * 0.125) 0;
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 17);
  top: calc(var(--cell) * 16);
}

.Main:nth-child(6) .Shape {
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 23);
  top: calc(var(--cell) * 14);
}

.Main:nth-child(7) .Shape {
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 15);
  top: calc(var(--cell) * 21);
}

.Main:nth-child(8) .Shape {
  border-radius: 0 0 0 calc(var(--cell) * 0.125);
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 19);
  top: calc(var(--cell) * 20);
}

.Main:nth-child(9) .Shape {
  border-radius: 0 0 calc(var(--cell) * 0.125) 0;
  width: calc(var(--cell) * 2);
  height: calc(var(--cell) * 2);
  left: calc(var(--cell) * 17);
  top: calc(var(--cell) * 26);
}

.Body {
  position: relative;
  display: none;
  pointer-events: auto;
  box-sizing: border-box;
  border-radius: 0 0 0 calc(var(--cell) * 0.25);
  transition: opacity 250ms linear;

  @media (min-width: 1024px) {
    width: calc(var(--cell) * 3);
    height: calc(var(--cell) * 3);
    margin-block-end: var(--cell);
  }
}

.IsVisible .Body,
.IsActive .Body,
.IsHidden .Body {
  display: block;
}

.IsActive .Body {
  margin-block-end: calc(var(--cell) * 2);

  @media (min-width: 1024px) {
    position: relative;
    width: calc(var(--cell) * 7);
    height: auto;
    margin-block-end: calc(var(--cell) * 2);
    padding-block-end: calc(var(--cell) * 4);
    z-index: 10;
  }
}

.Main:nth-child(1) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 15);
    margin-block-start: calc(var(--cell) * 6);
  }
}

.Main:nth-child(2) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 22);
    margin-block-start: calc(var(--cell) * 5);
  }
}

.Main:nth-child(3) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 13);
    margin-block-start: calc(var(--cell) * 10);
  }
}

.Main:nth-child(4) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 20);
    margin-block-start: calc(var(--cell) * 10);
  }
}

.Main:nth-child(5) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 15);
    margin-block-start: calc(var(--cell) * 16);
  }
}

.Main:nth-child(6) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 22);
    margin-block-start: calc(var(--cell) * 15);
  }
}

.Main:nth-child(7) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 13);
    margin-block-start: calc(var(--cell) * 20);
  }
}

.Main:nth-child(8) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 20);
    margin-block-start: calc(var(--cell) * 20);
  }
}

.Main:nth-child(9) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 15);
    margin-block-start: calc(var(--cell) * 26);
  }
}

.Main.IsActive:nth-child(1) .Body,
.Main.IsActive:nth-child(5) .Body,
.Main.IsActive:nth-child(9) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 11);
  }
}

.Main.IsActive:nth-child(2) .Body,
.Main.IsActive:nth-child(6) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 18);
  }
}

.Main.IsActive:nth-child(3) .Body,
.Main.IsActive:nth-child(7) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 9);
  }
}

.Main.IsActive:nth-child(4) .Body,
.Main.IsActive:nth-child(8) .Body {
  @media (min-width: 1024px) {
    margin-inline-start: calc(var(--cell) * 20);
  }
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left top;
  overflow: hidden;
  will-change: opacity, transform;
}

.Inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left top;
  background-color: var(--color-button);
  border-radius: 0 0 0 calc(var(--cell) * 0.25);
  will-change: opacity, transform;
}

.IsActive .Inner {
  border-radius: 0 0 0 calc(var(--cell) * 0.5);
}

.Title {
  position: relative;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--cell) * 0.5);
  padding: calc(var(--cell) * 0.5);
  box-sizing: border-box;
  overflow: hidden;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: anywhere;
  z-index: 50;

  @media (min-width: 1024px) {
    display: block;
    font-size: 1.4vw;
    padding: calc(var(--cell) * 0.25);
  }
}

.IsActive .Title {
  @media (min-width: 1024px) {
    font-size: 2.2vw;
    padding: calc(var(--cell) * 0.5);
    width: calc(var(--cell) * 6);
  }
}

.Wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;

  @media (min-width: 1024px) {
    padding-block-end: 0.5em;
  }
}

.Label {
  max-width: 100%;
  display: inline-block;
  will-change: opacity, transform;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: anywhere;
}

.Icon {
  flex-shrink: 0;
  width: 0.85em;
  height: 0.85em;

  @media (min-width: 1024px) {
    display: none;
  }
}

.IsActive .Icon {
  transform: rotate(180deg);
}

.Icon svg {
  fill: currentColor;
}

.RichText {
  position: relative;
  padding-inline: calc(var(--cell) * 0.5);
  display: none;
  will-change: opacity, transform;
  z-index: 50;
}

.IsActive .RichText {
  display: block;
}

.Illustration {
  position: relative;
  display: none;
  align-content: end;
  margin-block-start: calc(var(--cell) * -3);
  will-change: opacity, transform;

  @media (min-width: 1024px) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(var(--cell) * 1);
    margin: 0;
  }
}

.IsActive .Illustration {
  display: grid;
}

.Container img,
.Container svg {
  display: block;
}

.Log {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: normal;
  z-index: 300;
  color: black;
}
