.Main {
  --color: var(--color-white);
  --color-20: var(--color-purple-20);
  --color-40: var(--color-purple-40);
  --color-60: var(--color-purple-60);
  --color-80: var(--color-purple-80);
  --color-100: var(--color-purple-100);
  --color-body: var(--color-purple-100);

  position: relative;
  color: var(--color);
  grid-column: 1;
  grid-row: 1;
  pointer-events: none;

  @media (min-width: 1024px) {
    display: grid;
    grid-template: minmax(0, 1fr) / minmax(0, 1fr);
    box-sizing: border-box;
    transition: opacity linear 250ms;
  }
}

.IsHidden {
  display: none;

  @media (min-width: 1024px) {
    display: grid;
    opacity: 0.125;
  }
}

.IsActive,
.IsSubActive {
  display: block;
  align-self: start;
  padding-block-end: calc(var(--cell) * 5);
  margin-block-end: calc(var(--cell) * 5);

  @media (min-width: 1024px) {
    display: grid;
    z-index: 10;
    padding: 0;
    margin: 0;
  }
}

.IsPassive {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    opacity: 0;
  }
}

.Green {
  --color-20: var(--color-green-20);
  --color-40: var(--color-green-40);
  --color-60: var(--color-green-60);
  --color-80: var(--color-green-80);
  --color-100: var(--color-green-100);
  --color-body: var(--color-100);
}

.Red {
  --color-20: var(--color-red-20);
  --color-40: var(--color-red-40);
  --color-60: var(--color-red-60);
  --color-80: var(--color-red-80);
  --color-100: var(--color-red-100);
  --color-body: var(--color-80);
}

.Yellow {
  --color-20: var(--color-yellow-20);
  --color-40: var(--color-yellow-40);
  --color-60: var(--color-yellow-60);
  --color-80: var(--color-yellow-80);
  --color-100: var(--color-yellow-100);
  --color-body: var(--color-80);
}

.Back {
  position: sticky;
  top: var(--header);
  color: var(--color-blue);
  grid-template-columns: 1em minmax(0, 1fr);
  align-items: center;
  border-width: 0;
  border-radius: 0;
  padding-block: 1rem;
  padding-inline: calc(var(--cell) * 0.5);
  gap: 0.5em;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: start;
  display: none;
  pointer-events: auto;
  cursor: pointer;
  background-color: var(--color-white);
  z-index: 200;
}

.Back svg {
  aspect-ratio: 18 /11;
  height: 0.75em;
  fill: currentColor;
}

.IsActive .Back,
.IsSubActive .Back {
  display: grid;

  @media (min-width: 1024px) {
    display: none;
  }
}

.Pattern {
  position: absolute;
  grid-column: 1;
  grid-row: 1;
  width: calc(var(--cell) * 7);
  height: calc(var(--cell) * 3);
  transition: opacity 250ms linear;
}

.IsActive .Pattern {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    opacity: 0.125;
  }
}

.IsSubActive .Pattern {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    opacity: 0;
  }
}

.IsIntro .Pattern {
  display: none;
}

.Purple .Pattern {
  left: calc(var(--cell) * 1);
  top: calc(var(--cell) * 1);

  @media (min-width: 1024px) {
    left: calc(var(--cell) * 6);
    top: calc(var(--cell) * 3);
  }
}

.Green .Pattern {
  left: calc(var(--cell) * 5);
  top: calc(var(--cell) * 7);

  @media (min-width: 1024px) {
    left: calc(var(--cell) * 9);
    top: calc(var(--cell) * 10);
  }
}

.Red .Pattern {
  left: calc(var(--cell) * 2);
  top: calc(var(--cell) * 12);

  @media (min-width: 1024px) {
    left: calc(var(--cell) * 14);
    top: calc(var(--cell) * 3);
  }
}

.Yellow .Pattern {
  left: calc(var(--cell) * 5);
  top: calc(var(--cell) * 16);

  @media (min-width: 1024px) {
    left: calc(var(--cell) * 17);
    top: calc(var(--cell) * 7);
  }
}

.SVG {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: var(--color-20);
  will-change: opacity, transform;
}

.Body {
  display: block;
  position: relative;
  padding: calc(var(--cell) * 0.5);
  width: calc(var(--cell) * 4);
  min-height: calc(var(--cell) * 4);
  box-sizing: border-box;
  pointer-events: auto;
  grid-column: 1;
  grid-row: 1;
  align-self: start;
  margin-block-end: calc(var(--cell) * 2);
  transition: opacity linear 250ms;
}

.Purple .Body {
  margin-block-start: calc(var(--cell) * 2);
  margin-inline-start: calc(var(--cell) * 2);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 4);
    margin-inline-start: calc(var(--cell) * 7);
  }
}

.Purple.IsActive .Body {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 4);
    margin-inline-start: calc(var(--cell) * 5);
  }
}

.Purple.IsIntro .Body {
  margin-block-start: calc(var(--cell) * 2);
  margin-inline-start: calc(var(--cell) * 4);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 5);
    margin-inline-start: calc(var(--cell) * 11);
  }
}

.Green .Body {
  margin-block-start: calc(var(--cell) * 4);
  margin-inline-start: calc(var(--cell) * 8);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 7);
    margin-inline-start: calc(var(--cell) * 12);
  }
}

.Green.IsActive .Body {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 12);
  }
}

.Green.IsIntro .Body {
  margin-block-start: calc(var(--cell) * 5);
  margin-inline-start: calc(var(--cell) * 8);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 8);
    margin-inline-start: calc(var(--cell) * 13);
  }
}

.Red .Body {
  margin-block-start: calc(var(--cell) * 11);
  margin-inline-start: calc(var(--cell) * 3);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 2);
    margin-inline-start: calc(var(--cell) * 15);
  }
}

.Red.IsActive .Body {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 13);
  }
}

.Red.IsIntro .Body {
  margin-block-start: calc(var(--cell) * 6);
  margin-inline-start: calc(var(--cell) * 2);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 4);
    margin-inline-start: calc(var(--cell) * 15);
  }
}

.Yellow .Body {
  margin-block-start: calc(var(--cell) * 17);
  margin-inline-start: calc(var(--cell) * 7);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 8);
    margin-inline-start: calc(var(--cell) * 19);
  }
}

.Yellow.IsActive .Body {
  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 6);
    margin-inline-start: calc(var(--cell) * 17);
  }
}

.Yellow.IsIntro .Body {
  margin-block-start: calc(var(--cell) * 9);
  margin-inline-start: calc(var(--cell) * 6);

  @media (min-width: 1024px) {
    margin-block-start: calc(var(--cell) * 7);
    margin-inline-start: calc(var(--cell) * 17);
  }
}

.IsActive .Body {
  position: static;
  margin: 0;
  width: 100%;
  min-height: 0;
  padding-inline: calc(var(--cell) * 0.5);
  padding-block-start: calc(var(--cell) * 1);
  padding-block-end: var(--cell);
  background-color: var(--color-body);

  @media (min-width: 1024px) {
    position: relative;
    width: calc(var(--cell) * 6);
    padding: calc(var(--cell) * 0.5);
    padding-block-end: calc(var(--cell) * 2);
    margin-block-end: calc(var(--cell) * 2);
    background-color: transparent;
  }
}

.IsSubActive .Body {
  position: static;
  margin: 0;
  width: 100%;
  padding-inline: calc(var(--cell) * 0.5);
  padding-block-start: calc(var(--cell) * 1);
  padding-block-end: var(--cell);

  @media (min-width: 1024px) {
    position: relative;
    padding: calc(var(--cell) * 0.25);
    margin-block-start: calc(var(--cell) * 3);
    margin-inline-start: calc(var(--cell) * 5);
    width: calc(var(--cell) * 4);
    height: calc(var(--cell) * 4);
    opacity: 0;
  }
}

.IsIntro .Body {
  width: calc(var(--cell) * 3);
  height: calc(var(--cell) * 3);
  min-height: 0;

  @media (min-width: 1024px) {
    width: calc(var(--cell) * 2);
    height: calc(var(--cell) * 2);
  }
}

.IsPassive .Body {
  pointer-events: none;
}

.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: left top;
  overflow: hidden;
  will-change: opacity, transform;
}

.Inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-body);
  transform-origin: left top;
  will-change: opacity, transform;

  @media (min-width: 1024px) {
    background-color: var(--color-body);
  }
}

.IsActive .Inner {
  background-color: transparent;

  @media (min-width: 1024px) {
    background-color: var(--color-body);
  }
}

.Purple .Inner {
  border-radius: 0 0 0 calc(var(--cell) * 0.5);
}

.Green .Inner {
  border-radius: calc(var(--cell) * 0.5) 0 0 0;
}

.Red .Inner {
  border-radius: calc(var(--cell) * 0.5) 0 0 0;
}

.Yellow .Inner {
  border-radius: 0 0 0 calc(var(--cell) * 0.5);
}

.IsIntro .Inner {
  opacity: 0;
}

.Button {
  display: flex;
  gap: 0.5em;
  align-items: center;
  position: relative;
  font-size: 3.75vw;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  overflow: hidden;

  @media (min-width: 1024px) {
    font-size: 1.75vw;
    padding-block-end: 0.5em;
  }
}

.IsActive .Button {
  font-size: 30px;

  @media (min-width: 1024px) {
    font-size: 2.2vw;
  }
}

.IsSubActive .Button {
  font-size: 30px;

  @media (min-width: 1024px) {
    font-size: 2.2vw;
  }
}

.IsIntro .Button {
  display: none;
}

.Label {
  display: block;
  will-change: opacity, transform;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: anywhere;
}

.RichText {
  position: relative;
  display: none;
  padding-block-start: calc(var(--cell) * 0.5);
  will-change: opacity, transform;
  z-index: 50;

  @media (min-width: 1024px) {
    padding-block-start: calc(var(--cell) * 0.25);
  }
}

.IsActive .RichText {
  display: block;
}

.IsSubActive .RichText {
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
}

.TitleWrapper {
  display: none;
  font-weight: 700;
  line-height: 1;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    align-content: end;
    align-items: end;
    font-size: 2.2vw;
    color: var(--color-blue);
    grid-column: 1;
    grid-row: 1;
    padding-inline: calc(var(--cell) * 0.5);
    width: calc(var(--cell) * 5);
    height: calc(var(--cell) * 3);
    margin-block-start: 0;
    margin-inline-start: calc(var(--cell) * 2);
  }
}

.IsSubActive .TitleWrapper {
  @media (min-width: 1024px) {
    display: grid;
  }
}

.Title {
  font-weight: 700;
  font-size: 2.2vw;
  line-height: 1;
  color: var(--color-blue);
  overflow: hidden;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: anywhere;
}

.TitleInner {
  display: block;
  will-change: transform, opacity;
}

.Articles {
  background-color: var(--color-body);
  gap: 0.625rem;
  padding-inline: calc(var(--cell) * 0.5);
  padding-block-end: calc(var(--cell) * 3);
  display: none;

  @media (min-width: 1024px) {
    grid-template: minmax(0, 1fr) / minmax(0, 1fr);
    grid-column: 1;
    grid-row: 1;
    padding: 0;
    background-color: transparent;
  }
}

.IsActive .Articles {
  display: grid;
}

.IsSubActive .Articles {
  display: grid;
}

.Illustration {
  position: absolute;
  pointer-events: none;
  top: calc(var(--cell) * 1.25);
  left: calc(var(--cell) * -0.5);
  right: calc(var(--cell) * -0.5);
  height: 100%;
}

.IsActive .Illustration,
.IsSubActive .Illustration {
  top: calc(var(--cell) * 2);
  left: 0;
  right: 0;
  width: 100%;

  @media (min-width: 1024px) {
    height: 100%;
  }
}

.IsIntro .Illustration {
  display: none;
}

.Wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-content: end;
  transform-origin: center center;
  will-change: opacity, transform;
}

.Container {
  transform-origin: left top;
  will-change: opacity, transform;
}

.Container svg {
  display: block;
}
